import React, { useState } from 'react'
import "./AboutUs.css";
import transitionBottomImg from "../../images/Wave_White_bottom_right_shape_trans.png"

function AboutUs(props) {
    const data = props.data;
    console.log(data)

    return (
        <div className="about-container">
            <div className='about-parallax container' style={{ backgroundImage: `url("${data?.image ? data?.image?.sourceUrl : props?.csImage?.sourceUrl}")` }}>
                <div className='px-4 md:px-12 about-info'>
                    <h1 className='about-h1'>{data?.h1 ? data?.h1 : data?.name}</h1>
                    <div className='about-m-editor' dangerouslySetInnerHTML={{ __html: data.content ? data.content : data.description }} />
                </div>

                {/* <div style={{ minHeight: "70vh" }} /> */}
            </div>
            <div className='bt_bb_section_bottom_section_coverage_image' style={{zIndex: "10"}}>
                <img src={transitionBottomImg} />
            </div>
        </div>
    );
}

export default AboutUs;