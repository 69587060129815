import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import "./Advantages.css";

function Advantages(props) {
    const data = props.data;

    console.log(data)
    return (
        <div className='w-full relative'>
            <div className='ab-background-overlay' />
            <div className="m-auto container-cs text-left relative px-4 md:px-0 py-16 md:py-32">
                <div className='flex flex-wrap items-center w-full'>
                    <div className='w-full md:w-1/2 ab-adv-img'>
                        <div className='history-icon' style={{maxWidth: "300px"}}>
                            <GatsbyImage
                                image={data.image.localFile.childImageSharp.gatsbyImageData}
                                alt={data?.image?.altText}
                                className="m-auto"
                            />
                        </div>
                    </div>
                    <div className='w-full md:w-1/2 ab-adv-right pt-8 md:pt-32 md:pt-0'>
                        <h2 className='pb-8'>{data.title}</h2>
                        <div className='adv-editor' dangerouslySetInnerHTML={{ __html: data.content }} />
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Advantages;